<template>
  <p-list v-if="$hasPermission('templates.read')" />
  <p-not-found v-else />
</template>

<script>
import List from './../components/rules/List.vue';
import NotFound from './../components/NotFound.vue';

export default {
  components: {
    'p-list': List,
    'p-not-found': NotFound
  }
};
</script>
