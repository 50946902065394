var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isReady || _vm.isGetRequestPending)?_c('section',{staticClass:"rules"},[_c('p-loading')],1):_c('section',{staticClass:"rules"},[_c('header',[_c('h1',[_vm._v("Rules")]),_c('section',{staticClass:"action-wrapper"},[(_vm.$hasPermission('templates'))?_c('router-link',{attrs:{"to":"/rules/create"}},[_vm._v("+ new")]):_vm._e()],1)]),_c('section',[_c('p-list',{attrs:{"name":"rules","data":_vm.collection,"total":_vm.total,"metadata":{
        name: { label: 'Name', width: 'minmax(0, 3fr)' },
        description: { label: 'Description', width: 'minmax(0, 3fr)' },
        tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
        clients: { label: 'Clients', width: 'minmax(100px, 2fr)' },
        modifiedAt: { label: 'Last modification', width: '175px' }
      }},on:{"edit":_vm.edit},scopedSlots:_vm._u([{key:"__actions",fn:function(ref){
      var item = ref.item;
return [(_vm.$hasPermission('templates'))?_c('p-button',{staticStyle:{"font-size":"1.25rem"},attrs:{"variant":"text","title":"Clone this rule"},on:{"click":function($event){return _vm.clone(item.id)}}},[_vm._v(" ⎘")]):_vm._e(),_c('p-button',{attrs:{"variant":"text","title":"Edit this rule"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" ✎")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }