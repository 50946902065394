<template>
  <section v-if="!isReady || isGetRequestPending" class="rules">
    <p-loading />
  </section>

  <section v-else class="rules">
    <header>
      <h1>Rules</h1>
      <section class="action-wrapper">
        <router-link v-if="$hasPermission('templates')" to="/rules/create">+ new</router-link>
      </section>
    </header>
    <section>
      <p-list
        name="rules"
        :data="collection"
        :total="total"
        :metadata="{
          name: { label: 'Name', width: 'minmax(0, 3fr)' },
          description: { label: 'Description', width: 'minmax(0, 3fr)' },
          tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
          clients: { label: 'Clients', width: 'minmax(100px, 2fr)' },
          modifiedAt: { label: 'Last modification', width: '175px' }
        }"
        @edit="edit"
      >
        <template #__actions="{ item }">
          <p-button v-if="$hasPermission('templates')" variant="text" title="Clone this rule" style="font-size: 1.25rem" @click="clone(item.id)">
            &#x2398;</p-button
          >
          <p-button variant="text" title="Edit this rule" @click="edit(item.id)"> &#9998;</p-button>
        </template>
      </p-list>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from './../common/Button';
import Loading from './../common/Loading';
import List from './../common/List';

export default {
  components: {
    'p-list': List,
    'p-loading': Loading,
    'p-button': Button
  },
  data() {
    return {
      isReady: false
    };
  },
  computed: {
    ...mapState({
      collection: s => s.rules.collection,
      total: s => s.rules.total,
      isGetRequestPending: s => s.rules.isGetRequestPending
    })
  },
  async created() {
    await this.$store.dispatch('rules/getCollection');
    this.isReady = true;
  },
  methods: {
    async edit(id) {
      this.$router.push({ path: `/rules/${id}/edit` });
    },
    async clone(id) {
      const lock = this.$lock();

      try {
        const rule = await this.$store.dispatch('rules/clone', { id });

        this.$toast.success({
          title: 'Clone completed',
          message: `Rule '${rule.name}' was created.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Clone failed',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.rules {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;

  > header {
    padding: 0.5rem 0.5rem 0;
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
